
import 'assets/scss/style.scss'
import LandingPage from 'pages/LandingPage/LandingPage';
import Splash from 'pages/SplashScreen/Splash';
import RatingPage from 'pages/RatingPage/RatingPage';
import FinishPage from 'pages/FinishPage/FinishPage';
import StartPageView from 'pages/StartPage/StartPage-view';
import LoginPage from 'pages/LoginPage/LoginPage';
import Dashboard from 'pages/Dashboard/Dashboard';
import DashboardTicketView from 'pages/Dashboard/DashboardTicket/DashboardTicket-view';
import DashboardSalesView from 'pages/Dashboard/DashboardSales/DashboardSales-view';
import GenerateCodeView from 'pages/Dashboard/GenerateCode/GenerateCode-view';
import ClickPageView from 'pages/ClickPage/ClickPage-view';
import FormLogin from 'pages/LoginPage/FormLogin';
import EditPriceView from 'pages/Dashboard/EditPrice/EditPrice-view';
import EditVoucherView from 'pages/Dashboard/EditVoucher/EditVoucher-view';
import ReferralCodeView from 'pages/ReferralCode/ReferralCode-view';
import BookingPhotoView from 'pages/Booking/BookingPhoto-view';
import DashboardReferralView from 'pages/Dashboard/DashboardReferral/DashboardReferral-view';

// import ReferralCodeViewTest from 'pages/ReferralCodeTest/ReferralCodeTest-view';
import BookingPhotoViewTest from 'pages/BookingTest/BookingPhotoTest-view';
import AfterBookingView from 'pages/AfterBooking/AfterBooking-view';
import ReferralTransactionView from 'pages/ReferralTransaction/ReferralTransaction-view';

import DashboardRatingView from 'pages/Dashboard/DashboardRating/DashboardRating-view';


import {BrowserRouter as Routes, Route, Link} from 'react-router-dom'
import { ProtectedRoute } from 'protected';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" component={StartPageView}></Route>  
        <Route exact path="/LandingPage" component={LandingPage}></Route>
        <Route exact path="/RatingPage" component={RatingPage}></Route>
        <Route exact path="/FinishPage" component={FinishPage}></Route>
        <Route exact path="/ClickPage" component={ClickPageView}></Route>
        <Route exact path="/LoginPage" component={LoginPage}></Route>
        {/* <Route exact path="/LandingPage" component={LandingPage}></Route> */}
        <ProtectedRoute exact path="/Dashboard" component={Dashboard}/>
        <ProtectedRoute exact path="/Dashboard/Generate" component={GenerateCodeView}></ProtectedRoute>
        <ProtectedRoute exact path="/Dashboard-Ticket" component={DashboardTicketView}></ProtectedRoute>
        <ProtectedRoute exact path="/Dashboard-Sales" component={DashboardSalesView}></ProtectedRoute>
        <ProtectedRoute exact path="/EditPrice" component={EditPriceView}></ProtectedRoute>
        <ProtectedRoute exact path="/EditVoucher" component={EditVoucherView}></ProtectedRoute>
        <ProtectedRoute exact path="/Referral-Code" component={ReferralCodeView}></ProtectedRoute>  
        <ProtectedRoute exact path="/Dashboard-Rating" component={DashboardRatingView}></ProtectedRoute>  
        {/* <Route exact path="/Referral-CodeTest" component={ReferralCodeViewTest}></Route>   */}
       
        <Route exact path="/BookingPhoto" component={BookingPhotoView}></Route>
        <Route exact path="/BookingPhotoTest" component={BookingPhotoViewTest}></Route>

        <Route exact path="/Dashboard-Referral" component={DashboardReferralView}></Route>  
        <Route exact path="/AfterBooking" component={AfterBookingView}></Route>
        <Route exact path="/ReferralTransaction" component={ReferralTransactionView}></Route>



        
      </Routes>
    </div>
  );
}

export default App;
