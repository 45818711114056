import React, { useState, useRef } from "react";
import { useEffect } from "react";
import imageHero from "../../assets/images/bg.jpg";
import ImageClick from "../../assets/images/click.png";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import emailjs from "@emailjs/browser";
import "./booktest.css";
// import poonyalogo from "../../assets/images/poonyalogo.png";
import {ReactComponent as YourSvg } from "../../assets/images/logopoonya.svg";


import "../../assets/fonts/MatSaleh.otf";
import "../../assets/css/booking.css";
import iconpoonya from "../../assets/images/poonya.png";

import booking from "assets/images/booking.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import tatacara from "../../assets/images/tatacara.jpg";
var countqty = 1;
var qty = 1;

export default function BookingPhoto(props) {
  const form = useRef();
  const history = useHistory();
  
  const [bookingPhoto, setBookingPhoto] = useState();
  const [referralCode, setReferralCode] = useState();
  const [qty, setqty] = useState(1);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [referralPrice, setReferralPrice] = useState(0);

  const [modalShow, setModalShow] = React.useState(false);
  const [modalQris, setModalQris] = React.useState(false);
  const [qrCode, setQRCode] = React.useState("");
  const [payCode, setPayCode ] = React.useState();
  const [orderId, setOrderId] = React.useState();
  const [price, setPrice] = React.useState();


  useEffect(() => {
    Axios.get(
      "https://be.studiopoonya.net/setting/price"
      // "http://localhost:5000/setting/price"
    
      )
    .then((res) => {
      setCurrentPrice(res.data.value);
      console.log(currentPrice);
    })
    .catch((error) => {
      console.log("error");
    });
    // Sandbox
    // const snapSrcUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';
    // const myMidtransClientKey = 'SB-Mid-client-LuRh4t6hempU6vad'; //change this according to your client-key

    // //Production
    // const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    // const myMidtransClientKey = "Mid-client-XZCR9t5ePdhcGaNC"; //change this according to your client-key

    // const script = document.createElement("script");
    // script.src = snapSrcUrl;
    // script.setAttribute("data-client-key", myMidtransClientKey);
    // script.async = true;

    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);
 

  function applyCode() {
    if (referralCode != undefined && referralCode != "") {
      console.log(referralCode);
      Axios.get(
        `https://be.studiopoonya.net/payment/number/${referralCode}`
        // `http://localhost:5000/payment/number/${referralCode}`
      
        )
        .then((res) => {
          console.log(res.data);
          setReferralPrice(res.data.totalprice);
        })
        .catch((err) => {
          setReferralPrice(0);
        });
    } else {
      setReferralPrice(0);
    }
  }

  function Plus() {
    setqty(qty + 1);
  }

  function Minus() {
    if (qty > 1) {
      setqty(qty - 1);
    }
  }

  // function randomString(len, charSet) {
  //   charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  //   var randomString = '';
  //   for (var i = 0; i < len; i++) {
  //       var randomPoz = Math.floor(Math.random() * charSet.length);
  //       randomString += charSet.substring(randomPoz,randomPoz+1);
  //   }
  //   return randomString;
  // }


  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Tata Cara Pembayaran
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <img src={tatacara} width="400" height="500" />
          </center>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalQrisShow(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
        <Modal.Body>
        
          <center>
            <YourSvg
              
              style={{ width: "80", height: "80", color: "red" }}
            />{" "}
            <div style={{ marginTop: 10 }}></div>
            <h4 className="align-center" style={{ textAlign: "center",  color: "#0950E0",  fontSize: 30,}}>    
              Studio Poonya
            </h4>
          
          </center>
        </Modal.Body>

        </Modal.Header>
        <Modal.Body>
          <h3 className="align-center" style={{ textAlign: "center" }}>
            Scan QR Code
          </h3>
          <center>
            <QRCode
              value={qrCode}
              style={{ width: "180", height: "180", color: "red" }}
            />{" "}
            <div style={{ marginTop: 10 }}></div>
            <h3 className="align-center" style={{ textAlign: "center" }}>    
              Rp {price}
            </h3>
            <h4
              className="align-center"
              id="hiddentext"
              style={{ textAlign: "center", color: "red", display: "none" }}
            >
              Pastikan kembali pembayaran anda
            </h4>
          </center>
        </Modal.Body>

        <Modal.Body>
          <center>
          <div
          className="align-center"
          >
          <Button onClick={props.onHide}>Close</Button> &nbsp;&nbsp;&nbsp;
          <Button style={{ backgroundColor: "red" }} onClick={() => checkPaymentQR()}>
            Saya Sudah Bayar
          </Button>

        </div>
          </center>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button style={{ backgroundColor: "red" }} onClick={() => checkPaymentQR()}>
            Saya Sudah Bayar
          </Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

  
  function Booking() {
    var arrPaycode = [];

    if (bookingPhoto != undefined) {
      if (referralCode != undefined && referralCode != "") {
        console.log("masuk referal");
        var data = {
          transaction_number: referralCode,
          qty: qty,
        };

        var objString = new URLSearchParams(data).toString();
        Axios.get(  
          `https://be.studiopoonya.net/payment-qr/booking/referral/data?${objString}`
        
          // `http://localhost:5000/payment-qr/booking/referral/data?${objString}`
          )
          .then((result) => {

            console.log(result)
            setQRCode(result.data.payment.qr_string);  
            setOrderId(result.data.payment.order_id);
            // setOrderId(result.data.response.order_id);
            setPrice(result.data.payment.gross_amount);
            setModalQris(true);
            // for (let i = 0; i < result.data.code.length; i++) {
            //       arrPaycode.push(result.data.code[i]);
            //     }
            // setPayCode(arrPaycode);

          })
          .catch((error) => {
            console.log("error");
          });
      } else {
        var data = {
          username: bookingPhoto,
          qty: qty
        };

        Axios.post(
          "https://be.studiopoonya.net/users/booking", data
          // "http://localhost:5000/users/booking", data
          
          )
          .then((res) => {
            console.log("user booking found/created");
            Axios.post(
              "https://be.studiopoonya.net/payment-qr/booking", data
              // "http://localhost:5000/payment-qr/booking", data
              )
              .then((result) => {
                console.log(result.data)
                setQRCode(result.data.response.qr_string);  
                setOrderId(result.data.response.order_id);
                // setOrderId(result.data.response.order_id);
                setPrice(result.data.response.gross_amount);
                setModalQris(true);
                // for (let i = 0; i < result.data.code.length; i++) {
                //   arrPaycode.push(result.data.code[i]);
                // }
                // setPayCode(arrPaycode);
                console.log(payCode)
              })
              .catch((error) => {
                console.log("error booking", error);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      alert("Please input your email");
    }
  }
  
  // function generateQR(){
  //   Axios.post("https://be.studiopoonya.net/payment-qr")
  //   .then((result) => {
  //     console.log(result)
  //     setQRCode(result.data.response.qr_string);  
  //     setOrderId(result.data.response.order_id);
  //     setPrice(result.data.response.gross_amount);
  //     setModalQris(true);
  //   })
  // }

  function checkPaymentQR(){
    Axios.get(
      `https://be.studiopoonya.net/payment-qr/status/${orderId}`
      // `http://localhost:5000/payment-qr/status/${orderId}`
      
      )
    .then((res) => {
      console.log(res);
      if(res.data.response.transaction_status == 'settlement'){
      Axios.put(
        `https://be.studiopoonya.net/payment/update/status/${orderId}`
        // `http://localhost:5000/payment/update/status/${orderId}`
        )
        var data_email = {
          redeemcode: orderId,
          user_email: bookingPhoto,
        };
    emailjs
      .send(
        "service_g4thb5u",
        "template_zeszmog",
        data_email,
        "3s3SDsa482F2ptCU7"
      )
      .then(
        (result) => {
          window.location.href="https://photo.studiopoonya.net/AfterBooking"
          console.log(result.text);
          // history.push("AfterBooking");
        },
        (error) => {
          console.log(error.text);
        }
      );
      }
      else{
        var x = document.getElementById("hiddentext");
        if (x.style.display === "none") {
          x.style.display = "block";
        } else {
          x.style.display = "none";
        }
      }
      // console.log(currentPrice);
    })
    .catch((error) => {
      console.log("error");
    });
  }


  return (
    <div>
      <div>
        <div className="max-width">
          <section className="payment turquoise">
            <div className="grid-full padded-reverse">
              <div className="grid-whole padded">
                <h3 style={{ textAlign: "center", fontSize: 25 }}>
                  Let’s take your moment!
                </h3>
              </div>
              <div className="grid-5 padded m-grid-whole s-grid-whole xs-grid-whole">
                <div className="grid-whole">
                  <div>
                    <div>
                      <img src={iconpoonya} className="iconpoonya" />
                    </div>
                  </div>
                </div>
                <br />
              </div>
              <div className="grid-7 padded m-grid-whole s-grid-whole xs-grid-whole">
                  <div className="row">
                 
                    <label htmlFor="card-number">
                      <span
                        className="upper-bryant small-bold"
                        style={{ fontSize: 15 }}
                      >
                        Email
                      </span>
                    </label>
                    <br />
                    <input
                      type="email"
                      className="space"
                      size={12}
                      name="card-number"
                      id="card-number-email"
                      // placeholder="input email"
                      
                      onChange={(e) => setBookingPhoto(e.target.value)}
                      required
                    />
                </div>
                  <div className="row">
                    <label htmlFor="card-number">
                      <span
                        className="upper-bryant small-bold"
                        style={{ fontSize: 15 }}
                      >
                        Whatsapp Number
                      </span>
                    </label>
                    {/* <div></div> */}
                    <br />

                    </div>
                  <div className="row">

                    <input
                      type="number"
                      className="space"
                      size={12}
                      name="card-number"
                      id="card-number-wa"
                      // placeholder="input whatsapp number"
                      />
                      </div>
                <br />

                <div className="row">
                  <div>
                    <label htmlFor="card-number">
                      <span
                        className="upper-bryant small-bold"
                        style={{ fontSize: 15 }}
                      >
                        E-Voucher Photobooth
                      </span>
                    </label>{" "}
                    <br />
                    <label htmlFor="card-number">
                      <span
                        className="upper-bryant small-bold"
                        style={{ fontSize: 14 }}
                      >
                        Syarat & Ketentuan{" "}
                      </span>
                    </label>
                    <ol className="upper-bryant" style={{ fontSize: 13 }}>
                      <li>
                        1. E-voucher tidak dapat dikembalikan (non-refundable)
                      </li>
                      <li>2. E-voucher berlaku untuk 1 kali pemakaian</li>
                      <li>3. Cara penggunaan e-voucher:</li>
                      <ul>
                        <li>
                          - Pastikan Anda sudah mendapatkan e-voucher yang telah
                          dikirim melalui email yang Anda daftarkan
                        </li>
                        <li>
                          - Ketik e-voucher pada layar photobooth Studio Poonya
                          yang berlokasi di Double V Coffee, Rawamangun, Jakarta
                          Timur
                        </li>
                        <li>- Tekan tombol "redeem" dan mulai untuk foto</li>
                      </ul>
                    </ol>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <h3 style={{ alignSelf: "center", marginTop: 5 }}>{`Rp ${
                    currentPrice * qty
                  }`}</h3>{" "}
                  &nbsp;&nbsp;&nbsp;
                  {/* <button
                    class="button-1"
                    role="button"
                    onClick={Minus}
                    type="button"
                  >
                    -
                  </button> */}
                  {/* &nbsp;&nbsp;&nbsp;
                  <h4 style={{ alignSelf: "center", marginTop: 5 }}>{qty}</h4>
                  &nbsp;&nbsp;&nbsp; */}
                  {/* <button
                    class="button-2"
                    role="button"
                    onClick={Plus}
                    type="button"
                  >
                    +
                  </button> */}
                </div>
                <br />

                <div className="row">
                  <h4 style={{ alignSelf: "center" }}>Referral Code</h4>
                  <br />
                </div>

                <div className="row">
                  <h5 style={{ alignSelf: "center" }}>(optional)</h5>
                  <br />
                </div>

                <div className="row">
                  <input
                    onChange={(e) => {
                      setReferralCode(e.target.value);
                    }}
                    type="text"
                    className="space"
                    size={20}
                    name="card-number"
                    id="card-number"
                    placeholder="Input Code"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <button
                    class="button-3"
                    onClick={applyCode}
                    role="button"
                    style={{ alignSelf: "center", textAlign: "center" }}
                  >
                    Apply Code
                  </button>
                </div>

                <div className="row">
                  {/* wrong code warna merah */}
                  <h5 style={{ color: referralCode == 0 ? "red" : "none" }}>
                    {referralPrice != 0
                      ? "You are using the referral code"
                      : null}
                  </h5>
                </div>
                <br />

                <div className="row">
                  <h4
                    style={{
                      textDecoration:
                        referralCode != "" && referralCode != undefined
                          ? "line-through"
                          : "none",
                      color:
                        referralCode != "" && referralCode != undefined
                          ? "red"
                          : "none",
                    }}
                  >
                    Total Price : {`Rp ${currentPrice * qty}`}
                  </h4>
                  <br />
                </div>

                <div className="row">
                  <h4>
                    {referralCode != "" && referralCode != undefined
                      ? `Total Price with Referral : Rp ${
                          currentPrice * qty - referralPrice * qty
                        }`
                      : null}
                  </h4>
                </div>

                <br />
                <div className="row">
                <>
              {/* <button
                className="btn px-3 start-photo"
                onClick={() => setModalShow(true)}
              >
                Cara Pembayaran
              </button> */}
              {/* <button
                className="btn px-3 start-photo"
                onClick={() => Booking()}
              >
                Test QR
                              </button> */}

                              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <ModalQrisShow
                show={modalQris}
                onHide={() => setModalQris(false)}
              />
            </>
                  <button
                    class="button-4"
                    onClick={() => Booking()}
                    type="button"
                    role="button"
                    style={{ alignSelf: "center", textAlign: "center" }}
                  >
                    Pay Now
                  </button>
                </div>

                {/* <button type="submit" className="btn" id="update-payment">
              Update Card
            </button> */}
              </div>
              <div className="clear" />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
