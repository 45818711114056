import React, { useState } from "react";
import imageHero from "../../assets/images/newpoo/test3.png";
import tatacara from "../../assets/images/newpoo/payment1.png";
import startbutton from "../../assets/images/newpoo/startbutton.png";
import eminagif from "../../assets/images/newpoo/poonyabloom.jpg";
import { useHistory } from "react-router-dom";

const SplashScreen = ({ onFinish }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [scale, setScale] = useState(1);

  const handleSplashClick = () => {
    setScale(0.9); // Scale down
    setTimeout(() => {
      setFadeOut(true); // Start fade out
      setTimeout(onFinish, 500); // Wait for fade out to finish before calling onFinish
    }, 100); // Delay for scale animation
  };

  return (
    <div
      style={{
        ...splashScreenStyle,
        opacity: fadeOut ? 0 : 1,
        transform: `scale(${scale})`,
      }}
      onClick={handleSplashClick}
    >
      <button style={closeButtonStyle} onClick={handleSplashClick}>
        ✖
      </button>
      <img src={eminagif} alt="Splash Screen" />
    </div>
  );
};

// CSS Styles as JS objects
const splashScreenStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
  transition: "opacity 0.5s ease, transform 0.1s ease", // Add transition for scale and opacity
};

const closeButtonStyle = {
  position: "absolute",
  top: 20,
  right: 20,
  background: "#fff",
  border: "2px solid #0000FF", // Border for button
  borderRadius: "12px", // Rounded corners
  fontSize: "24px",
  cursor: "pointer",
  color: "#0000FF",
  padding: "5px 10px", // Padding for better appearance
  transition: "background 0.3s, color 0.3s",
};

const heroStyle = {
  backgroundImage: `url(${imageHero})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  height: "1000px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
};

export default function Hero(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true); // State for controlling splash screen

  const moveLandingPage = () => {
    history.push('LandingPage');
    history.go(0);
  };

  const handleSplashFinish = () => {
    setIsLoading(false); // Hide splash screen
  };

  return (
    <div>
      {isLoading ? (
        <SplashScreen onFinish={handleSplashFinish} />
      ) : (
        <div style={heroStyle}>
          <img
            src={tatacara}
            alt="Tatacara Image"
            height="450px"
            width="1100px"
            style={{ marginTop: -100 }}
          />

          <img
            src={startbutton}
            style={{
              position: "absolute",
              paddingTop: 400,
              animation: "zoomInOut 3s infinite alternate",
              cursor: "pointer",
            }}
            onClick={moveLandingPage}
          />
          <style>
            {`
            @keyframes zoomInOut {
              0% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
              100% {
                transform: scale(1);
              }
            }
            `}
          </style>
        </div>
      )}
    </div>
  );
}
